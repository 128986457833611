<template>
    <div id="tooltip-component" class="tooltip-wrapper relative w-fit">
        <slot name="activator">
            <v-icon color="info"> mdi-information </v-icon>
        </slot>

        <div class="tooltip-content pa-2 absolute white--text" :style="`margin-left: -${width / 2}px; width: ${width}px; `">
            <slot name="content">
                {{ content }}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TooltipComponent',
    props: {
        content: {
            type: String,
            required: false
        },
        width: {
            type: Number,
            required: false,
            default: 200
        }
    }
}

</script>

<style>
.tooltip-content {
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    bottom: calc(100% + 5px);
    left: 50%;
    background-color: #3c4959;
}

.tooltip-wrapper:hover .tooltip-content {
    opacity: 1;
}

.tooltip-content::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3c4959 transparent transparent transparent;
}
</style>
