import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IClientSubscription } from '@/models/IClientSubscription'

const resource = 'api/v1/subscription/client'

export default {
    getSubscriptionClient(): Promise<AxiosResponse<IServerResponse<IClientSubscription>>> {
        return axios.get(`${resource}`)
    },

    getTotalSubscriptionByIdClient(idClient: number): Promise<AxiosResponse<IServerResponse<number>>> {
        return axios.get(`${resource}/${idClient}/total`)
    },

    unsubscribeUserClient(): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.delete(`${resource}`)
    },

    subscribeUserClient(): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}`)
    },
}
