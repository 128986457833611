<template>
    <div id="list-order-validated-component" class="min-height-component-550">
        <p class="font-14 mb-4 font-color-medium">
            {{ $t('list_order_validated_component.page_description') }}
        </p>

        <template v-if="!loading">
            <template v-if="orders.length > 0">
                <datatable-filter-component :headers="headers" />

                <p class="font-weight-bold">
                    {{ $t('list_order_validated_component.order_length_end', [orders.length]) }}
                </p>

                <v-data-table
                    :headers="headers"
                    :items="orders"
                    :items-per-page="5"
                    :loading="loading"
                    :search="search"
                    class="stripe-date-table"
                    disable-sort
                >
                    <template v-slot:item.date_delivery="{ item }">
                        <span>{{ moment(item.date_delivery).format(date_format) }}</span>
                    </template>

                    <template v-slot:item.quantity="{ item }">
                        <span>{{ item.quantity }} m³</span>
                    </template>

                    <template v-slot:item.delivered_volume="{ item }">
                        <span v-if="item.delivered_volume">{{ item.delivered_volume }} m³</span>
                    </template>
                </v-data-table>
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('list_order_validated_component.no_order_validated_yet')"
                />
            </template>
        </template>

        <template v-else>
            <v-skeleton-loader type="table-thead, table-tbody" />
        </template>
    </div>
</template>

<script>
import DatatableFilterComponent from '@/components/Global/DatatableFilterComponent'
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'

export default {
    name: 'ListOrderValidatedComponent',
    components: { EmptyPageComponent, DatatableFilterComponent },
    data() {
        return {
            headers: [
                {text: this.$t('datatable_order_component.order_number'), value: 'erp_order_number', filterable: true},
                {text: this.$t('datatable_order_component.asset_concerned'), value: 'asset.name', filterable: true},
                {text: this.$t('datatable_order_component.city'), value: 'address', filterable: true},
                {text: this.$t('datatable_order_component.reel_delivery_date'), value: 'date_delivery', filterable: true},
                {text: this.$t('datatable_order_component.order_form'), value: 'purchase_order', filterable: true},
                {text: this.$t('datatable_order_component.liquid'), value: 'liquid', filterable: true},
                {text: this.$t('datatable_order_component.quantity_ordered'), value: 'quantity', filterable: true},
                {text: this.$t('datatable_order_component.delivered'), value: 'delivered_volume', filterable: true},
            ],
            orders: [],
            loading: false,
            search: '',
        }
    },
    created() {
        this.getOrderCockpitCompleted()
    },
    methods: {
        getOrderCockpitCompleted() {
            this.loading = true

            OrderCockpitRepository.getOrderCockpitListByState(9)
                .then((success) => {
                    this.orders = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>