<template>
    <div id="order-life-cycle-component">
        <v-card class="elevation-0">
            <v-card-title class="d-flex justify-space-between pointer" @click="is_open = !is_open">
                <span class="font-16">
                    {{ $t('order_life_cycle_component.operation_control_proposal') }}
                </span>

                <v-icon>
                    {{ is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-card-title>

            <v-expand-transition>
                <div v-if="is_open">
                    <v-card-text class="text-center">
                        <div class="position-relative w-fit mx-auto">
                            <img height="500" src="/images/global/cycle_vie_order.png" />

                            <tooltip-component
                                :content="$t('order_life_cycle_component.anticipate_rupture_before_happens')"
                                class="seuil-declenche-tootltip"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn seuil-declenche" />
                                </template>
                            </tooltip-component>

                            <tooltip-component
                                :content="$t('order_life_cycle_component.order_proposal_sent')"
                                class="proposition-commande-tooltip"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn proposition-commande" />
                                </template>
                            </tooltip-component>

                            <tooltip-component
                                class="date-livraison-tooltip"
                                :content="$t('order_life_cycle_component.delivery_date_proposed')"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn date-livraison" />
                                </template>
                            </tooltip-component>

                            <tooltip-component
                                class="proposition-valide-tooltip"
                                :content="$t('order_life_cycle_component.once_confirmed_sent_teams_validation')"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn proposition-valide" />
                                </template>
                            </tooltip-component>

                            <tooltip-component
                                class="verify-validation-tooltip"
                                :content="$t('order_life_cycle_component.teams_check_order')"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn verify-validation" />
                                </template>
                            </tooltip-component>

                            <tooltip-component
                                class="in-truck-tooltip"
                                :content="$t('order_life_cycle_component.order_on_its_way')"
                            >
                                <template v-slot:activator>
                                    <div class="life-cycle-fake-btn in-truck" />
                                </template>
                            </tooltip-component>
                        </div>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import TooltipComponent from '@/components/Global/TooltipComponent.vue'

export default {
    name: 'OrderLifeCycleComponent',
    components: { TooltipComponent },
    data() {
        return {
            is_open: false,
        }
    },
}
</script>

<style>
.life-cycle-fake-btn {
    width: 102px;
    height: 25px;
    left: -50px;
}

.seuil-declenche {
    position: absolute;
    top: 128px;
}

.seuil-declenche-tootltip {
    position: absolute;
    top: -2px;
    left: 208px;
}

.proposition-commande {
    position: absolute;
    top: 108px;
    right: 15px;
}

.proposition-commande-tooltip {
    position: absolute;
    top: 100px;
    right: 67px;
}

.date-livraison-tooltip {
    position: absolute;
    bottom: 245px;
    right: 67px;
}

.date-livraison {
    position: absolute;
    top: 108px;
}

.proposition-valide-tooltip {
    position: absolute;
    bottom: 140px;
    right: 218px;
}

.proposition-valide {
    position: absolute;
    top: 109px;
}

.verify-validation-tooltip {
    position: absolute;
    bottom: 235px;
    left: 65px;
}

.verify-validation {
    position: absolute;
    top: 108px;
}

.in-truck-tooltip {
    position: absolute;
    top: 103px;
    left: 62px;
}

.in-truck {
    position: absolute;
    top: 108px;
}
</style>