var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"order-product-price-detail-component"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"d-flex justify-space-between pointer",on:{"click":function($event){_vm.is_open = !_vm.is_open}}},[_c('span',{staticClass:"font-16"},[_vm._v(" "+_vm._s(_vm.$t('display_price_component.price_details'))+" ")]),_c('v-icon',[_vm._v(" "+_vm._s(_vm.is_open ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1),_c('v-expand-transition',[(_vm.is_open)?_c('div',[_c('v-card-text',[_c('v-row',[(!_vm.loading_products)?_vm._l((_vm.assets),function(asset){return _c('v-col',{key:asset.id_asset,attrs:{"cols":"12","lg":"4","md":"6"}},[_c('v-card',{staticClass:"background-color-main",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-2"},[_c('div',{staticClass:"font-weight-bold mb-0 font-14 mb-1"},[_c('v-icon',[_vm._v(" mdi-storage-tank-outline")]),_vm._v(" "+_vm._s(asset.name)+" ")],1),_c('div',{staticClass:"font-12 font-color-light line-height-12 mb-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-outline")]),_vm._v(" "+_vm._s(_vm.getFormattedAddress(asset.domicile))+" ")],1),(
                                                _vm.getProductsPriceByAsset(asset.id_asset)[0] &&
                                                _vm.getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                            )?[_vm._l((_vm.getProductsPriceByAsset(asset.id_asset)),function(product_price){return [_c('div',{key:product_price.id_liquid},[_c('strong',[_vm._v(_vm._s(product_price.name))]),_vm._v(": "+_vm._s(product_price.price)+" €/m³ ")])]}),_c('div',{staticClass:"font-color-light mt-2 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('order_products_price_detail_component.cotation_method'))+": "),_c('span',{staticClass:"font-color-light"},[_vm._v(" "+_vm._s(_vm.$t( 'display_price_component.' + _vm.getProductsPriceByAsset(asset.id_asset)[0].cotation_method ))+" ")])]),(
                                                    ['platts_jour', 'cnr_1', 'platts_mois_1'].includes(
                                                        _vm.getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                                    )
                                                )?_c('v-alert',{staticClass:"my-0",attrs:{"border":"left","dense":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t( `display_price_component.${ _vm.getProductsPriceByAsset(asset.id_asset)[0].cotation_method }_message`, { month: _vm.moment().format('MMMM'), } ))+" ")]):_vm._e()]:[_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('display_price_component.price_not_available')))])]],2)],1)],1)}):_vm._l((3),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","lg":"4","md":"6"}},[_c('v-skeleton-loader',{key:n,attrs:{"type":"image"}})],1)})],2)],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }