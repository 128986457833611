<template>
    <div id="order-product-price-detail-component">
        <v-card class="elevation-0">
            <v-card-title class="d-flex justify-space-between pointer" @click="is_open = !is_open">
                <span class="font-16">
                    {{ $t('display_price_component.price_details') }}
                </span>

                <v-icon>
                    {{ is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-card-title>

            <v-expand-transition>
                <div v-if="is_open">
                    <v-card-text>
                        <v-row>
                            <template v-if="!loading_products">
                                <v-col v-for="asset in assets" :key="asset.id_asset" cols="12" lg="4" md="6">
                                    <v-card class="background-color-main" elevation="0">
                                        <v-card-text class="pa-2">
                                            <div class="font-weight-bold mb-0 font-14 mb-1">
                                                <v-icon> mdi-storage-tank-outline</v-icon>
                                                {{ asset.name }}
                                            </div>

                                            <div class="font-12 font-color-light line-height-12 mb-2">
                                                <v-icon small> mdi-map-marker-outline</v-icon>
                                                {{ getFormattedAddress(asset.domicile) }}
                                            </div>

                                            <template
                                                v-if="
                                                    getProductsPriceByAsset(asset.id_asset)[0] &&
                                                    getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                                "
                                            >
                                                <template v-for="product_price in getProductsPriceByAsset(asset.id_asset)">
                                                    <div :key="product_price.id_liquid">
                                                        <strong>{{ product_price.name }}</strong
                                                        >: {{ product_price.price }} €/m³
                                                    </div>
                                                </template>

                                                <div class="font-color-light mt-2 mb-1">
                                                    {{ $t('order_products_price_detail_component.cotation_method') }}:
                                                    <span class="font-color-light">
                                                        {{
                                                            $t(
                                                                'display_price_component.' +
                                                                    getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                                            )
                                                        }}
                                                    </span>
                                                </div>

                                                <v-alert
                                                    v-if="
                                                        ['platts_jour', 'cnr_1', 'platts_mois_1'].includes(
                                                            getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                                        )
                                                    "
                                                    border="left"
                                                    class="my-0"
                                                    dense
                                                    text
                                                    type="info"
                                                >
                                                    {{
                                                        $t(
                                                            `display_price_component.${
                                                                getProductsPriceByAsset(asset.id_asset)[0].cotation_method
                                                            }_message`,
                                                            {
                                                                month: moment().format('MMMM'),
                                                            }
                                                        )
                                                    }}
                                                </v-alert>
                                            </template>

                                            <template v-else>
                                                <span class="warning--text">{{ $t('display_price_component.price_not_available') }}</span>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>

                            <template v-else>
                                <v-col v-for="n in 3" :key="n" cols="12" lg="4" md="6">
                                    <v-skeleton-loader :key="n" type="image" />
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'

export default {
    name: 'OrderProductPriceDetailComponent',
    data() {
        return {
            products_price: [],
            loading_products: true,
            is_open: true,
        }
    },
    created() {
        this.getProductsListByAsset()
    },
    computed: {
        assets() {
            return this.$store.getters['tanks/tanks']
        },
    },
    methods: {
        async getProductsListByAsset() {
            this.loading_products = true
            this.products_price = []
            await Promise.all(
                this.assets.map((asset) => {
                    return Promise.resolve(this.fetchProductsByAsset(asset.id_asset))
                })
            ).finally(() => {
                this.loading_products = false
            })
        },

        fetchProductsByAsset(id_asset) {
            return OrderCockpitRepository.getOrderCockpitProductsByIdAsset(id_asset)
                .then((success) => {
                    this.products_price.push({ id_asset: id_asset, product: success.data.data })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
        getProductsPriceByAsset(id_asset) {
            return this.products_price.find((product) => product.id_asset === id_asset).product
        },
    },
}
</script>
