<template>
    <div id="list-order-being-validated-component" class="min-height-component-550">
        <p class="font-14 mb-2 font-color-medium mb-4">
            {{ $t('list_order_being_validated_component.truck_process_delivery') }}
        </p>

        <template v-if="!loading">
            <template v-if="orders.length > 0">
                <datatable-filter-component :headers="headers" />

                <p class="font-weight-bold">
                    {{ $t('list_order_being_validated_component.order_length', [orders.length]) }}
                </p>

                <v-data-table
                    :headers="headers"
                    :items="orders"
                    :loading="loading"
                    :search="search"
                    class="stripe-date-table"
                    disable-sort
                    item-key="order_number"
                >
                    <template v-slot:item.dates_desired_delivery_json="{ item }">
                        <div class="d-flex gap-1">
                            <v-chip v-for="date in JSON.parse(item.dates_desired_delivery_json)" :key="date" color="disabled" small>
                                {{ moment(date).format(date_format) }}
                            </v-chip>
                        </div>
                    </template>

                    <template v-slot:item.quantity="{ item }">
                        <span>{{ item.quantity }} m³</span>
                    </template>

                    <template v-slot:item.date_delivery="{ item }">
                        <span v-if="item.date_delivery">{{ moment(item.date_delivery).format(date_format) }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex flex-column w-fit py-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="userHasAccess('ORDC', role_edit) && item.state === 4"
                                        v-bind="attrs"
                                        @click="$refs.CancelOrderDialogComponent.openCancelOrderDialog(item)"
                                        v-on="on"
                                    >
                                        mdi-cancel
                                    </v-icon>
                                    <v-chip v-if="item.max_cancellation_date && item.state === 4" small>
                                        {{ $t('list_order_being_validated_component.order_automatically_validated_on') }} :
                                        {{ moment(item.max_cancellation_date).format('D/MM/YYYY à HH:mm') }}
                                    </v-chip>
                                </template>
                                <span class="white--text">{{ $t('cancel_order_dialog_component.cancel_order') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('list_order_being_validated_component.no_order_being_validated_yet')"
                />
            </template>
        </template>

        <template v-else>
            <v-skeleton-loader type="table-thead, table-tbody" />
        </template>

        <cancel-order-dialog-component ref="CancelOrderDialogComponent" v-on:update:order="getOrderCockpitInProgress" />
    </div>
</template>

<script>
import DatatableFilterComponent from '@/components/Global/DatatableFilterComponent'
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'
import CancelOrderDialogComponent from '@/components/Order/CancelOrderDialogComponent.vue'

export default {
    name: 'ListOrderBeingValidatedComponent',
    components: { CancelOrderDialogComponent, EmptyPageComponent, DatatableFilterComponent },
    data() {
        return {
            orders: [],
            headers: [
                {
                    text: this.$t('datatable_order_component.order_number'),
                    value: 'erp_order_number',
                    filterable: true,
                },
                { text: this.$t('datatable_order_component.asset_concerned'), value: 'asset.name', filterable: true },
                { text: this.$t('datatable_order_component.city'), value: 'address', filterable: true },
                { text: this.$t('datatable_order_component.expected_delivery_date'), value: 'date_delivery', filterable: true },
                { text: this.$t('datatable_order_component.order_form'), value: 'purchase_order', filterable: true },
                { text: this.$t('datatable_order_component.liquid'), value: 'liquid', filterable: true },
                { text: this.$t('datatable_order_component.quantity'), value: 'quantity', filterable: true },
                { text: this.$t('global.actions'), value: 'actions', filterable: false, align: 'end' },
            ],
            loading: false,
            search: '',
        }
    },
    created() {
        this.getOrderCockpitInProgress()
    },
    methods: {
        getOrderCockpitInProgress() {
            this.loading = true

            OrderCockpitRepository.getOrderCockpitListByState('2,3,4,5,6,7,8')
                .then((success) => {
                    this.orders = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>
