<template>
    <div id="card-pending-order-component">
        <v-card elevation="0">
            <v-card-title class="d-flex justify-space-between">
                {{ $t('card_pending_order_component.order_proposal') }}

                <chips-last-statement :date="order.date_creation" :previous_text="$t('chips_last_statement.send')" colorless />
            </v-card-title>

            <v-card-text>
                <order-summary-asset-component :order="order" />
                <v-divider class="mb-3" />
                <order-summary-data-component :order="order" />
                <v-divider class="my-3" />

                <div class="d-flex flex-column gap-2">
                    <div class="d-flex gap-2 align-start">
                        <v-icon class="font-color-light"> mdi-water-outline</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.product') }}
                            </p>
                            {{ order.liquid }}
                        </div>
                    </div>

                    <div class="d-flex gap-2 align-start">
                        <v-icon class="font-color-light"> mdi-storage-tank-outline</v-icon>
                        <div class="w-100">
                            <display-price-component :price="order.product_price" />
                        </div>
                    </div>

                    <div v-if="order.desired_date_delivery && order.desired_date_delivery_end" class="d-flex gap-2">
                        <v-icon class="font-color-light"> mdi-calendar</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.desired_delivery_date') }}
                            </p>
                            <span class="font-color-light">
                                Du {{ moment(order.desired_date_delivery).format(date_format) }} au
                                {{ moment(order.desired_date_delivery_end).format(date_format) }}
                            </span>
                        </div>
                    </div>
                </div>

                <!--
                                <div v-if="show('order_number')">
                                    <span v-html="$t('card_pending_order_component.order_is_pending_confirmation', [order.order_number])" />
                                </div>

                                <div v-if="show('product_price.cotation_method') && order.product_price" class="my-1">
                                    <span class="font-weight-bold">{{ $t('card_pending_order_component.price_liquid') }}</span>
                                    <br />
                                    <span>{{ order.product_price.cotation_method }}</span>
                                    <br />
                                    <span v-if="order.product_price.price">{{ order.product_price.price }} €/m³</span>
                                </div>

                                <div v-if="show('desired_date_delivery') && order.desired_date_delivery" class="my-1">
                                    <span class="font-weight-bold">{{ $t('card_pending_order_component.desired_delivery_date') }}</span>
                                    <br />
                                    <span>{{ moment(order.desired_date_delivery).format(date_format) }}</span>
                                </div>
                -->

                <v-tooltip max-width="300" content-class="text-center" top open-delay="100" :disabled="userHasAccess('ORDC', role_edit)">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-btn
                                :disabled="!userHasAccess('ORDC', role_edit)"
                                :to="'/orders_cockpit/' + order.id_order"
                                block
                                class="mt-2"
                                color="primary"
                            >
                                <span
                                    :class="
                                        userHasAccess('ORDC', role_edit)
                                            ? 'font-weight-bold secondary--text'
                                            : 'font-weight-bold font-color-light'
                                    "
                                >
                                    {{ $t('card_pending_order_component.see_order_proposal') }}
                                </span>
                            </v-btn>
                        </div>
                    </template>

                    <span class="white--text">
                        {{ $t('card_pending_order_component.not_have_rights_view_order_proposal') }}
                    </span>
                </v-tooltip>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ChipsLastStatement from '@/components/Global/ChipsLastStatement'
import OrderSummaryAssetComponent from '@/components/Order/OrderSummaryAssetComponent.vue'
import OrderSummaryDataComponent from '@/components/Order/OrderSummaryDataComponent.vue'
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'
import DisplayPriceComponent from '@/components/Order/DisplayPriceComponent.vue'

export default {
    name: 'CardPendingOrderComponent',
    components: { DisplayPriceComponent, OrderSummaryDataComponent, OrderSummaryAssetComponent, ChipsLastStatement },
    props: {
        order: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            liquids: [],
        }
    },
    computed: {
        showPrice() {
            return isNaN(this.order.product_price.price)
                ? this.order.product_price.cotation_method
                : this.order.product_price.price + ' €/m³'
        },
    },
    created() {
        this.fetchLiquids()
    },
    methods: {
        fetchLiquids() {
            OrderCockpitRepository.getOrderCockpitProductsByIdAsset(this.order.asset.id_asset)
                .then((success) => {
                    this.liquids = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
    },
}
</script>
