<template>
    <div id="list-order-pending-validation-component" class="min-height-component-550">
        <v-expand-transition>
            <v-alert
                v-if="display_no_user_subscribe"
                class="elevation-0"
                close-icon="mdi-close"
                dense
                dismissible
                elevation="1"
                icon="mdi-alert-octagon-outline"
                text
                type="error"
            >
                <p class="mb-0 line-height-16 font-14 error--text">
                    {{ $t('list_order_pending_validation_component.no_user_subscribe') }}
                </p>
            </v-alert>
        </v-expand-transition>

        <v-expand-transition>
            <v-alert
                v-if="subscribed && !$store.getters['user_connected/user_connected'].alert_mail"
                class="elevation-0"
                close-icon="mdi-close"
                dense
                dismissible
                elevation="1"
                icon="mdi-alert-octagon-outline"
                text
                type="warning"
            >
                <p class="mb-0 line-height-16 font-14 warning--text">
                    {{ $t('list_order_pending_validation_component.cannot_receive_email_update_contact_preferences') }}
                </p>
            </v-alert>
        </v-expand-transition>

        <p class="font-14 mb-2 font-color-medium">
            {{ $t('list_order_pending_validation_component.page_description') }}
        </p>

        <p class="font-14 mb-5 font-color-medium">
            {{ $t('list_order_pending_validation_component.contact_1') }}
            <a class="font-color-medium font-weight-bold" href="mailto:serviceclient.oleo100@groupeavril.com">
                serviceclient.oleo100@groupeavril.com </a
            >.
        </p>

        <order-life-cycle-component class="mb-4" />

        <order-product-price-detail-component />

        <v-switch v-model="subscribed" class="mb-4" inset @change="orderSubscriptionHandler()">
            <template v-slot:label>
                <span class="font-14 text-default">
                    {{ $t('list_order_pending_validation_component.proposal_order_by_mail') }}
                </span>
            </template>
        </v-switch>

        <template v-if="!loading_orders">
            <template v-if="orders.length > 0">
                <v-row class="mb-1 mx-0 justify-space-between">
                    <p class="font-weight-bold">
                        {{ $t('list_order_pending_validation_component.order_length', [orders.length]) }}
                    </p>
                    <v-btn class="square-btn-icon" color="secondary" outlined>
                        <v-icon color="secondary">mdi-download</v-icon>
                    </v-btn>
                </v-row>

                <v-row>
                    <v-col v-for="order in orders" :key="order.order_number" cols="12" lg="6">
                        <card-pending-order-component :order="order" />
                    </v-col>
                </v-row>
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('list_order_pending_validation_component.no_order_pending_validation_yet')"
                />
            </template>
        </template>

        <template v-else>
            <v-row>
                <v-col v-for="n in 2" :key="n" cols="12" md="6">
                    <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import CardPendingOrderComponent from '@/components/Order/CardPendingOrderComponent'
import ClientSubscriptionRepository from '@/repositories/ClientSubscriptionRepository'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'
import OrderLifeCycleComponent from '@/components/Order/OrderLifeCycleComponent.vue'
import OrderProductPriceDetailComponent from '@/components/Order/OrderProductsPriceDetailComponent.vue'

export default {
    name: 'ListOrderWaitingValidationComponent',
    components: {
        OrderProductPriceDetailComponent,
        OrderLifeCycleComponent,
        EmptyPageComponent,
        CardPendingOrderComponent,
    },
    data() {
        return {
            search: '',
            subscribed: null,
            loading_orders: false,
            display_no_user_subscribe: false,
        }
    },
    computed: {
        orders: {
            get: function () {
                return this.$store.getters['orders_cockpit/orders_cockpit_pending_validation']
            },
            set: function (value) {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation')
            },
        },
        orders_copy: {
            get: function () {
                return this.$store.getters['orders_cockpit/orders_cockpit_pending_validation']
            },
            set: function (value) {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation')
            },
        },
    },
    created() {
        this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation')
        this.getSubscriptionDetails()
        this.getTotalSubscriptionByIdClient()
    },
    methods: {
        getSubscriptionDetails() {
            ClientSubscriptionRepository.getSubscriptionClient()
                .then((success) => {
                    this.subscribed = false
                    if (success.data.data) {
                        this.subscribed = success.data.data.alert_new_order
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getTotalSubscriptionByIdClient() {
            ClientSubscriptionRepository.getTotalSubscriptionByIdClient(this.$store.getters['user_connected/user_connected'].id_client)
                .then((success) => {
                    if (success.data.data === 0) {
                        this.display_no_user_subscribe = true
                    } else {
                        this.display_no_user_subscribe = false
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        orderSubscriptionHandler() {
            if (this.subscribed) {
                this.subscribeUser()
            } else {
                this.unsubscribeUser()
            }
        },

        subscribeUser() {
            ClientSubscriptionRepository.subscribeUserClient()
                .then(() => {
                    this.showSnackbar('success', this.$t('list_order_pending_validation_component.subscribe_success'))
                    this.subscribed = true
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.getTotalSubscriptionByIdClient()
                })
        },

        unsubscribeUser() {
            ClientSubscriptionRepository.unsubscribeUserClient()
                .then(() => {
                    this.showSnackbar('success', this.$t('list_order_pending_validation_component.unsubscribe_success'))
                    this.subscribed = false
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.getTotalSubscriptionByIdClient()
                })
        },
    },
    watch: {
        search: {
            handler() {
                if (!this.search) {
                    this.orders = this.orders_copy
                } else {
                    let orders_local = []
                    this.orders_copy.forEach((order) => {
                        let include = false

                        if (this.filter('order_number')) {
                            if (order.order_number.includes(this.search)) {
                                orders_local.push(order)
                                include = true
                            }
                        }

                        if (this.filter('asset.name_asset')) {
                            if (order.asset.name_asset.includes(this.search)) {
                                orders_local.push(order)
                                include = true
                            }
                        }

                        if (this.filter('address') && !include) {
                            if (order.address.includes(this.search)) {
                                orders_local.push(order)
                                include = true
                            }
                        }

                        if (this.filter('quantity') && !include) {
                            if (order.quantity.toString().includes(this.search)) {
                                orders_local.push(order)
                                include = true
                            }
                        }

                        if (this.filter('product_price.cotation_method') && !include) {
                            if (order.product_price.cotation_method.includes(this.search)) {
                                orders_local.push(order)
                            }
                        }

                        if (this.filter('product_price.price') && !include) {
                            if (order.product_price.price.toString().includes(this.search)) {
                                orders_local.push(order)
                            }
                        }
                    })

                    this.orders = orders_local
                }
            },
            deep: true,
        },
    },
}
</script>
