import { render, staticRenderFns } from "./OrderCockpitPage.vue?vue&type=template&id=13c30138&v-if=userHasAccess('ORDC'%2C%20role_vision)&"
import script from "./OrderCockpitPage.vue?vue&type=script&lang=js&"
export * from "./OrderCockpitPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports