<template>
    <div id="cancel-order-dialog-component">
        <v-dialog v-model="dialog_cancel_order" max-width="500px">
            <v-card>
                <v-card-title class="mb-0">
                    <p class="font-weight-bold font-18 text-uppercase error--text">
                        {{ $t('cancel_order_dialog_component.cancel_order') }}
                    </p>
                </v-card-title>

                <v-card-text class="py-0">
                    <v-textarea v-model="motif" :label="$t('cancel_order_dialog_component.motif')" filled />

                    <v-menu :close-on-content-click="false" min-width="auto" offset-y transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="pb-1" v-bind="attrs" v-on="on">
                                <v-combobox
                                    v-model="new_desired_delivery_dates"
                                    :label="$t('cancel_order_dialog_component.new_desired_delivery_dates')"
                                    chips
                                    filled
                                    hide-details
                                    multiple
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    small-chips
                                />
                            </div>
                        </template>

                        <v-date-picker v-model="new_desired_delivery_dates" multiple no-title />
                    </v-menu>
                    <p class="font-10 font-color-medium mb-4 mt-n1">
                        {{ $t('card_validate_order_component.please_check_possible_delivery_days') }}
                    </p>
                </v-card-text>

                <v-card-actions class="justify-end pb-4">
                    <v-btn color="accent" text @click="closeCancelOrderDialog()">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn :disabled="!valid" :loading="loading" class="btn-error-loader font-weight-bold" text @click="cancelOrder()">
                        <span :class="valid ? 'error--text font-weight-bold' : 'font-color-light font-weight-bold'">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'

export default {
    name: 'CancelOrderDialogComponent',
    data() {
        return {
            dialog_cancel_order: false,
            order: {},
            motif: '',
            new_desired_delivery_dates: [],
            menu: false,
            loading: false,
        }
    },
    methods: {
        openCancelOrderDialog(order) {
            this.order = order
            this.dialog_cancel_order = true
        },
        closeCancelOrderDialog() {
            this.order = {}
            this.dialog_cancel_order = false
        },
        cancelOrder() {
            this.loading = true
            OrderCockpitRepository.cancelOrderCockpit(this.order.id_order, {
                motif: this.motif,
                new_desired_delivery_dates: this.new_desired_delivery_dates,
            })
                .then(() => {
                    this.showSnackbar('success', this.$t('cancel_order_dialog_component.order_cancelled'))
                    this.$emit('update:order')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                    this.dialog_cancel_order = false
                })
        },
    },
    computed: {
        valid() {
            return this.motif !== ''
        },
    },
}
</script>